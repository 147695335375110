<template>
    <div class="main-content" style="
      padding-bottom: 10vh !important
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 100%;
        background-position: center;
      ">
        <div class="dashboard-banner position-relative">
            <div class="d-flex justify-content-center align-items-end">
            <!-- <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("dashboard") }}</h4> -->
            </div>
            <!-- <div class="position-absolute h-100 w-100" style="background: #d2d2d24d" v-if="isLoading">
            <div class="px-2 py-1 rounded" style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #add8e6;
                ">
                <p class="mb-0">{{ $t("loading...") }}</p>
            </div>
            </div> -->
        </div>
        <div class="m-3 mx-3 mt-4">
            <div class="header">
                <!-- <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack"> -->
                <h1 class="main_text text text_main_title">{{ $t("earnings") }}</h1>
                <!-- <i class="fa fa-history info-icon" aria-hidden="true"></i> -->
                <!-- <img src="../../../assets/images/etnex_buy/question.png" alt="Info" class="info-icon"> -->
            </div>

            <div class="etnex-purchase-container colorful_bg">
              <div class="purchase-content row">
                <div class="col-6">
                  <div class="asset-info">
                  <p class="text-12 text mb-0">ETNEX - </p>
                  <p class="text-15 text font-weight-semibold mb-0">{{ $t("my_wallet") }}</p>
                  <p class="text-8 text mb-0">{{ $t("Your Gateway to Smart Profitability") }}</p>
                  
                  </div>
                </div>
                <div class="col-6">
                  <div class="asset-info text-right">
                    <img src="../../../assets/images/etnex_home/bar_graph.png" alt="Info" class="info-icon" style="margin-top:-2rem">
                  </div>
                </div>
              </div>
            </div>

            <h2 class="text_h2_etnex text text-light-green mt-2">{{ $t("etnex_tradeai") }}</h2>
            <div class="etnex-package-container black-dark-blue-card">
            <div class="package-content">
                <div class="purchase-details">
                    <div class="d-flex justify-content-between align-items-center">
                        <h3 class="text text_16 weight_600">{{ $t("etnex_ai") }} <span class="text text_16 weight_400">{{ $t("overview") }}</span></h3>
                        <!-- <div class="button-group">
                            <button class="btn btn-timeframe">24H</button>
                            <button class="btn btn-timeframe">7D</button>
                            <button class="btn btn-timeframe">All</button>
                        </div> -->
                        <!-- <div class="button-group btn-group-toggle" data-toggle="buttons">
                          <label class="btn btn-timeframe" :class="{ 'active': selected === '24h' }">
                            <input type="radio" name="options" id="option1" autocomplete="off" value="24h" v-model="selected"> 24H
                          </label>
                          <label class="btn btn-timeframe" :class="{ 'active': selected === '7d' }">
                            <input type="radio" name="options" id="option2" autocomplete="off" value="7d" v-model="selected"> 7D
                          </label>
                          <label class="btn btn-timeframe" :class="{ 'active': selected === 'all' }">
                            <input type="radio" name="options" id="option3" autocomplete="off" value="all" v-model="selected"> All
                          </label>
                        </div> -->
                    </div>
                    <hr>
                    <div class="stat-item">
                        <div class="stat-title text">{{ $t("total_arbitrage") }}</div>
                        <div class="stat-value text">{{ this.total_staking }}</div>
                    </div>
                    <hr>
                    <div class="stat-item">
                        <div class="stat-title text">{{ $t("total_etn_earned") }}</div>
                        <div class="stat-value text">{{ this.etnex_earn }} ETNEX</div>
                    </div>
                    <hr>
                    <div class="stat-item">
                        <div class="stat-title text">{{ $t("total_usdt_earned") }}</div>
                        <div class="stat-value text">{{ this.usdt_earn }} USDT</div>
                    </div>
                    <!-- <hr> -->
                    <!-- <div class="purchase-statement-container">
                        <button class="make-purchase-button">
                            {{ $t("start_trade") }}
                        </button>
                    </div> -->
                </div>
            </div>
            </div>
            <h2 class="text_h2_etnex text pt-10 text-light-green mt-3">{{ $t("trade_his") }}</h2>
            <div class="scrollable-container">
              <div v-if="PackageRecord.length > 0">
                <div class="notification-card" v-for="record in PackageRecord" :key="record.id">
                    <div class="icon">
                        <img src="../../../assets/images/etnex_earnings/robot.png" alt="icon" class="icon">
                    </div>
                    <div class="content">
                    <div class="upper-row">
                        <span class="title text">{{ $i18n.locale === 'en' ? record.package.name : record.package.name_cn }}</span>
                        <span class="date text">{{ formatDate(record.created_at) }}</span>
                    </div>
                    <div class="lower-row">
                        <span class="amount text text-light-green text-10">{{ record.earn }} ETNEX</span>
                        <span class="change text-light-green">{{ record.price }} ETNEX</span>
                    </div>
                  </div>
                </div>
              </div>
              <h3 v-else class="no-data-text text">
                {{ $t("no_data") }}
              </h3>
            </div>
            <div class="purchase-statement-container" v-if="moreRecords">
                <button class="make-purchase-button" style="width: 40% !important;" @click="loadMore">
                    {{ $t("load_more") }}
                </button>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { getStakingRecord, getStakingInfo } from "../../../system/api/api";
  import { handleError } from "../../../system/handleRes";
  import { mapGetters } from "vuex";
  export default {
    components: {
      ...mapGetters(["lang"]),
    },
    data() {
      return {
        selected: '24h',
        currentPage: 1,
        PackageRecord: [],
        moreRecords: false,
        etnex_earn: 0,
        total_staking: 0,
        usdt_earn: 0,
      };
    },
    mounted() {
      this.loadItems();
    },

    methods: {
      
      goBack() {
        this.$router.go(-1)
      },

      formatDate(datetime) {
        return datetime.split(' ')[0];
      },
      loadMore() {
        this.currentPage++;
        this.loadItems();
      },
      loadItems(){
          var result = getStakingRecord(
          this.currentPage,
        );
        var self = this;
        result
          .then(function (value) {
            // console.log("API Response:", value);
            self.PackageRecord = self.PackageRecord.concat(value.data.data.user_package.data);
            if (!value.data.data.user_package.next_page_url) {
              self.moreRecords = false;
            }else{
              self.moreRecords = true;
            }
            // console.log(self.walletRecord);

          })
          .catch(function (error) {
            self.isLoading = false;
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });

          var result_info = getStakingInfo();
          result_info
          .then(function (value) {
            // console.log(value);
            self.etnex_earn = value.data.data.dasboard.etnex_earn;
            self.total_staking = value.data.data.dasboard.total_staking;
            self.usdt_earn = value.data.data.dasboard.usdt_earn;
            // console.log(self.etnex_earn);
          })
          .catch(function (error) {
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });
      }
    },
  };
  </script>
  
<style scoped>

    .scrollable-container {
    max-height: 240px; 
    overflow-y: auto; 
    }
    .notification-card {
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, #152331 0%, #000000 100%);
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 7px;
    }

    .icon {
        margin-right: 10px;
        flex-shrink: 0;
    }

    .icon img {
        background-color: transparent;
        border-radius: 5px;
        padding: 8px;
        width: 50px;
    }

    .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .upper-row, .lower-row {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .date {
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .amount {
        font-weight: 600;
        letter-spacing: 1px;
        font-family: 'Poppins';
    }

    .change {
        border-radius: 10px;
        padding: 2px 8px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
        font-family: 'Poppins';
    }

    .pt-10{
        padding-top: 10px;
    }

    .button-group .btn-timeframe {
        border: none;
        background-color: #7E8088;
        color: #222223!important;
        margin-left: 0.25rem;
        border-radius: 8px;
        box-shadow: none;
        transition: background-color 0.3s;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: 26px;
        height: 26px;
        padding: 0 1rem;
    }

    .button-group .btn-timeframe.active,
    .button-group .btn-timeframe:hover {
        background-color: #3BEDEA;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        color: #fff !important;
    }

    .btn-group-toggle>.btn input[type="radio"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }

    
    hr {
        border: none;
        height: 1px;
        background-color: #393737;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .stat-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

  
  /* text css */
  .text_main_title{
    flex-grow: 1;
    text-align: center;
  }

  .no-data-text{
    font-weight: 400;
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
    margin-top: 40px;
    color: var(--Secondary, #7E8088) !important;
  }
  .stat-title{
    color: var(--Secondary, #7E8088);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .stat-value{
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
  }
  .text{
    color: var(--Main, #FFF);
    font-family: Poppins;
    font-style: normal;
  }
  
  .main_text{
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin-top: 7px;
  }

  .text_16{
    margin-bottom: -1px;
    font-size: 16px;
    line-height: 18px;
  }
  .weight_400{
    font-weight: 400;
  }
  .weight_600{
    font-weight: 600;
  }
  .text_h2_etnex{
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
  
  /* text css end */
  

  .etnex-package-container {
    max-width: 100%;
    margin: auto;
    color: white;
    padding: 12px;
    font-family: 'Poppins', sans-serif;
    border-radius: 8px;
    border: 1px solid var(--Neon-Blue, #0BDDCC);
  }
  
  .header {
    display: flex;
    /* padding: 0px 16px; */
    margin-top: 4rem !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  /* .back-arrow-icon, .info-icon {
    cursor: pointer;
  } */

  .info-icon{
    cursor: pointer;
    color: #fff;
    font-size: 18px;
  }
  
  
  .package-content h2 {
    margin-bottom: 16px;
  }
  
  .purchase-details {
    border-radius: 8px;
  }
  
  
  
  .make-purchase-button {
    width: 90%;
    border-radius: 8px;
    background: var(--Primary, #3BEDEA);
    color: rgb(0, 0, 0);
    padding: 12px;
    border: none;
    color: var(--Dark-Purple, #251740);
    text-align: center;
    /* Button */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }
  
  
  .purchase-statement-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: white;
    font-family: 'Poppins', sans-serif;
  }
  </style>
  